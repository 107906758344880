import { SubmitHandler } from 'react-hook-form';
import ConfettiExplosion from 'react-confetti-explosion';
import * as Yup from 'yup';

import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { InfoAlert } from '../../atoms/InfoAlert';
import { Copy } from '../../atoms/Typography';
import { ChevronRightIcon } from '../../atoms/Icons';
import { useState } from 'react';

export const AcceptJobModal = ({ handleCancel, handleSubmit }: AcceptJobModalProps) => {
  const [isExploding, setIsExploding] = useState(false);

  const onSubmit: SubmitHandler<FormData> = async () => {
    setIsExploding(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await handleSubmit({});
  };

  return (
    <>
      <>{isExploding && <ConfettiExplosion />}</>
      <H2>Accept</H2>
      <InfoAlert>
        <Copy marginBottom={16}>Well done! You have got a new Job!</Copy>
        <Copy styleLevel={2}>You can now update your job application status to reflect this exciting development.</Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>

          <PrimaryButton type="submit" inline iconRight={<ChevronRightIcon color={Colors.White} />}>
            Accept
          </PrimaryButton>
        </WrapperButtons>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({});

type FormData = Yup.InferType<typeof validationSchema>;

interface AcceptJobModalProps {
  handleSubmit: (a: any) => void;
  handleCancel: (a: any) => void;
}
